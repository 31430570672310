import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiGetExpertisesResponse } from "@/api/expertise/types/apiGetExpertisesResponse";
import { ApiCreateExpertiseRequest } from "@/api/expertise/types/apiCreateExpertiseRequest";

export class ExpertiseController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	getExpertises = async (type: string, projectId: string) => {
		const url = urls.expertise.getExpertises.replace(urlTemplateParts.id, type).replace(urlTemplateParts.secondId, projectId);
		return await this.client.get<ApiGetExpertisesResponse>(url, {});
	};
	
	createExpertise = async (type: string, projectId: string, payload: ApiCreateExpertiseRequest) => {
		const url = urls.expertise.createExpertise.replace(urlTemplateParts.id, type).replace(urlTemplateParts.secondId, projectId);
		return await this.client.post<{ error: boolean, errorArr: string[] }>(url, payload);
	};
}
