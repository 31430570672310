<template>
	<div>
		<!--		модальное окно оповещения об отправке плана-графика траншей на согласование менеджеру проекта		-->
		<frp-dialog v-model="isSuccessSentTrancheScheduleForApprovalByManagerDialogEnabled"
					:title="$t('dialogs.successSentTrancheScheduleForApprovalByManager.title')">
			<template #footer>
				<frp-btn color="blue"
						 elevation="0"
						 dark
						 @click="isSuccessSentTrancheScheduleForApprovalByManagerDialogEnabled = false">
					{{ $t("buttons.close") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<!--если режим стоит в статусе на редактирование-->
		<template v-if="can(Permissions.TRANCHES_TRANCHE_SCHEDULE_UPDATE) && mode === TrancheScheduleModeTypeEnum.EDIT">
			<frp-btn v-if="trancheSchedule.tranches.length"
					 :disabled="isTrancheScheduleLoading || isTrancheScheduleSaving"
					 class="px-3" outlined color="primary"
					 @click="handleCancel">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			
			<frp-btn :loading="isTrancheScheduleLoading || isTrancheScheduleSaving"
					 :disabled="!isSendingAvailable || !isStateContainsUnsavedChanges(TrancheScheduleSnapshotKeysEnum.TRANCHES)"
					 color="blue" dark class="px-3"
					 @click="handleSaveTrancheSchedule">
				<span v-if="trancheSchedule.tranches.length">{{ $t("buttons.saveChanges") }}</span>
				<span v-else>{{ $t("buttons.saveTrancheSchedule") }}</span>
			</frp-btn>
		</template>
		
		<template v-else-if="mode === TrancheScheduleModeTypeEnum.READ">
			<frp-btn class="px-3" color="blue" dark
					 v-if="can(Permissions.TRANCHES_TRANCHE_SCHEDULE_UPDATE)"
					 :disabled="isTrancheScheduleLoading"
					 @click="setMode(TrancheScheduleModeTypeEnum.EDIT)">
				{{ $t("buttons.editTranchesSchedule") }}
			</frp-btn>
		</template>
		
		<tranches-info-dialog :type="TrancheInfoDialogTypeEnum.TRANCHE_SENT_SUCCESS" v-model="isInfoDialogOpened"/>
	</div>
</template>

<script>
import { ApiTrancheStatusTypeEnum } from "@/api/tranches/types/ApiTrancheStatusTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import LoanConfirmDialog from "@/components/dialogs/LoanConfirmDialog.vue";
import TranchesContentLayout from "@/components/layouts/TranchesContentLayout.vue";
import { Permissions } from "@/constants/permissions";
import authorizationMixin from "@/mixins/authorizationMixin";
import { namespace, actionTypes, mutationTypes, getterTypes } from "@/store/tranches/modules/application/types";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import {
	TrancheScheduleOrderConditionModeTypeEnum
} from "@/store/tranches/modules/application/types/TrancheScheduleOrderConditionModeTypeEnum";
import { TrancheScheduleSnapshotKeysEnum } from "@/store/tranches/modules/application/types/TrancheScheduleSnapshotKeysEnum";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import { TrancheInfoDialogTypeEnum } from "@/types/tranches/TrancheInfoDialogTypeEnum";
import TranchesInfoDialog from "@/views/tranches/dialogs/TranchesInfoDialog.vue";
import TranchesTrancheApplicationGeneration from "@/views/tranches/sections/generation/TranchesTrancheApplicationGeneration.vue";
import TranchesTrancheApplicationProjectInfo from "@/views/tranches/sections/projectInfo/TranchesTrancheApplicationProjectInfo.vue";
import TranchesTrancheApplicationTrancheSchedule
	from "@/views/tranches/sections/trancheSchedule/TranchesTrancheApplicationTrancheSchedule.vue";
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [authorizationMixin],
	data() {
		return {
			ApiTrancheStatusTypeEnum,
			TrancheInfoDialogTypeEnum,
			TrancheScheduleModeTypeEnum,
			TrancheScheduleOrderConditionModeTypeEnum,
			TrancheScheduleStatusTypeEnum,
			TrancheScheduleSnapshotKeysEnum,
			isWithdrawTrancheScheduleDialogEnabled: false,
			isSuccessSentTrancheScheduleForApprovalByManagerDialogEnabled: false,
			isInfoDialogOpened: false
		};
	},
	computed: {
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges,
			isDraftTrancheScheduleStatus: getterTypes.isDraftTrancheScheduleStatus,
			isPendingTrancheScheduleStatus: getterTypes.isPendingTrancheScheduleStatus
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			items: state => state.listing.items,
			mode: state => state.mode,
			trancheScheduleSum: state => state.trancheScheduleSum,
			editableItems: state => state.editableItems,
			projectInfo: state => state.projectInfo,
			trancheSchedule: state => state.trancheSchedule,
			isTrancheScheduleWithdrawing: state => state.isTrancheScheduleWithdrawing,
			isTrancheScheduleSaving: state => state.isTrancheScheduleSaving,
			isTrancheScheduleOrderConditionsSaving: state => state.isTrancheScheduleOrderConditionsSaving,
			isTrancheScheduleFormValid: state => state.isTrancheScheduleFormValid,
			sendingToApprovalTrancheNumber: state => state.sendingToApprovalTrancheNumber,
			isTrancheScheduleUnsavedChangesDialogEnabled: state => state.isTrancheScheduleUnsavedChangesDialogEnabled
		}),
		isTrancheScheduleLoading() {
			return this.isTrancheScheduleSaving
				|| this.isTempDocumentFilesInfoUploading
				|| this.isTempDocumentFileInfoDeleting
				|| !isNil(this.sendingToApprovalTrancheNumber);
		},
		isSendingAvailable() {
			const availableForNonAdmin = this.isTrancheScheduleFormValid
											&& this.trancheScheduleSum === this.projectInfo.agreement.exactFrpSum;
			
			return this.can(Permissions.TRANCHES_SAVE_AS_ADMIN) ? true : availableForNonAdmin;
		}
	},
	methods: {
		...mapActions({
			rollback: actionTypes.cancelChanges,
			updateTrancheSchedule: actionTypes.updateTrancheSchedule,
			updateTrancheScheduleOrderConditions: actionTypes.updateTrancheScheduleOrderConditions,
			withdrawTrancheSchedule: actionTypes.confirmTranche,
			sendTrancheForApproval: actionTypes.sendTrancheForApproval
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE,
			//Модалка на не сохраненные данные находится по пути: ./sections/trancheSchedule/TranchesTrancheApplicationTrancheSchedule.vue
			setIsTrancheScheduleUnsavedChangesDialogEnabled: mutationTypes.SET_IS_TRANCHE_SCHEDULE_UNSAVED_CHANGES_DIALOG_ENABLED
		}),
		async handleSaveTrancheSchedule() {
			// Создаем или же обновляем график траншей
			await this.updateTrancheSchedule();
			this.setIsTrancheScheduleUnsavedChangesDialogEnabled(false);
		},
		async handleWithdrawTrancheSchedule() {
			this.isWithdrawTrancheScheduleDialogEnabled = false;
			
			await this.withdrawTrancheSchedule();
		},
		async handleSendTrancheForApproval() {
			const res = await this.sendTrancheForApproval();
			
			if(res)
				this.isInfoDialogOpened = true;
		},
		async handleCancel() {
			if(!this.isStateContainsUnsavedChanges(TrancheScheduleSnapshotKeysEnum.TRANCHES) || this.editableItems.every(x => !x.amount && !x.plannedDate))
				await this.onCancelFormSubmit(TrancheScheduleSnapshotKeysEnum.TRANCHES);
			else
				this.setIsTrancheScheduleUnsavedChangesDialogEnabled(true);
		},
		async onCancelFormSubmit(key) {
			await this.rollback(key);
			
			this.setMode(TrancheScheduleModeTypeEnum.READ);
		}
	},
	components: {
		TranchesInfoDialog,
		FrpDialog,
		LoanConfirmDialog,
		FrpBtn,
		TranchesContentLayout,
		TranchesTrancheApplicationProjectInfo,
		TranchesTrancheApplicationTrancheSchedule,
		TranchesTrancheApplicationGeneration
	}
};
</script>


<style scoped>

</style>